<template>
  <div class="home">
    <Header/>
    <ContainerCards/>
    <!-- <PopUpJob v-if="openedJob" :dataWorks="dataWorks" :workSelectedId="workSelectedId"/> -->
  </div>

</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import ContainerCards from '@/components/ContainerCards.vue'
// import PopUpJob from '@/components/PopupJob.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    ContainerCards,
    // PopUpJob
  }
}
</script>
