<template>
  <router-view/>
</template>
<style lang="sass">

*
  padding: 0
  margin: 0

:root
  --black: #1f2020
  --accent-light: #a7ff38
  --accent-dark: #3a3b3d
  --accent-gradient: linear-gradient(45deg, var(--accent-light) 0%, #38ffa2 100%)

@font-face
  font-family: 'PPNeueMachina'
  src: url('@/assets/fonts/PPNeueMachina-PlainBold.ttf')
  font-display: swap

html,
#app
  font-family: system-ui, sans-serif
  background: var(--black)
  background-size: 224px
  min-height: 100%
  scroll-behavior: smooth
  scrollbar-color: var(--accent-light) var(--accent-dark)
  scrollbar-width: thin

::-webkit-scrollbar
  width: 6px

::-webkit-scrollbar-thumb
  background: var(--accent-light)
  border-radius: 5px

</style>
