<template>
	<div class="loading-wrapper" v-show="isLoading">
		<SpinnerLoader />
	</div>

  <div class="jobs-container">
    <main class="bundle_cardwork">
      <CardWork
        v-for="(job, index) in jobs"
        :jobData="job"
        :key="job.slug"
        :delay="index"
        @click="openJob(job.slug)"
      />
    </main>
  </div>

  <div class="buttons_pages">
    <div
      class="pagination_static pagination_static--before"
      :disabled="this.currentPage <= 1"
      @click="goToPage(this.currentPage - 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 -960 960 960"
      >
        <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
      </svg>
    </div>

    <div
      :class="
        this.currentPage === page
          ? 'pagination--active'
          : 'pagination--inactive'
      "
      class="pagination"
      v-for="page in this.totalPages"
      @click="goToPage(page)"
      :key="page"
    >
      {{ page }}
    </div>

    <div
      class="pagination_static pagination_static--after"
      :disabled="this.currentPage >= this.totalPages"
      @click="goToPage(this.currentPage + 1)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 -960 960 960"
      >
        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
      </svg>
    </div>
  </div>
  <PopupJob
    v-show="openedJob"
    :idJob="this.selectedJob"
    :currentPage="this.currentPage"
    @closePopUp="closeJob"
  />
</template>

<script>
import CardWork from '@/components/CardWork.vue';
import PopupJob from '@/components/PopupJob.vue';
import SpinnerLoader from '@/components/SpinnerLoader.vue';

export default {
	name: 'ContainerCards',
	components: {
		CardWork,
		PopupJob,
		SpinnerLoader,
	},
	data() {
		return {
			jobs: [],
			baseJobsURL: `${process.env.VUE_APP_JOBS_API_BASE_URL}/api/job/?&per_page=${process.env.VUE_APP_JOBS_PER_PAGE}`,
			jobsURL: null,
			jobCount: 0,
			totalPages: 0,
			isLoading: true,
			currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
			openedJob: false,
			selectedJob: String,
		};
	},
	mounted() {
		if(this.$route.params.id != undefined ) {
			this.openJob(this.$route.params.id)
		}else{
			this.openedJob = false
		}
		this.goToPage(this.currentPage)
	},
	watch: {
		openedJob(opened){
			opened ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto'
		},
		'$route.params.id'(currentLocation){
			currentLocation ? null : this.openedJob = false
		},
	},
	methods: {
		goToPage: function (page) {
			this.currentPage = page
			this.isLoading = true
			this.$router.push({ params: '', query: { 'page': this.currentPage } })
			this.getJobsData();
		},
		getJobsData: function () {
			this.jobsURL = `${this.baseJobsURL}&page=${this.currentPage}`
			window.scrollTo(0, 0);

      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'es',
        },
      };

      fetch(this.jobsURL, requestOptions)
        .then((res) => res.json())
        .then(
          (res) => (
            (this.jobs = res.results),
            (this.jobCount = res.count),
            (this.totalPages = res.total_pages),
            (this.isLoading = false)
          )
        )
        .catch((err) => {
          console.log(err);
        });
    },
    openJob: function (slug) {
      this.$router.push({ query: { page: this.currentPage } });
      this.selectedJob = slug;
      this.openedJob = true;
    },
    closeJob: function () {
      this.$router.push({ path: '/', query: { page: this.currentPage } });
      this.openedJob = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.loading-wrapper
  width: 80%
  min-height: 100%
  margin: 5rem auto
  padding: 1rem

.jobs-container
  width: 100%
  min-height: fit-content
  transition: opacity 1s ease-out

.bundle_cardwork
  display: grid
  flex: 1
  grid-template-columns: repeat(auto-fill, minmax(350px, 4fr))
  gap: 30px
  z-index: 2
  margin: auto
  padding: 1rem
  width: 80%
  font-size: 20px
  line-height: 1.6

.buttons_pages
  display: flex
  justify-content: space-around
  overflow: hidden
  width: 25%
  margin: 24px auto

.pagination_static
  width: 25px
  height: 50px
  border: none
  cursor: pointer
  display: flex
  justify-content: center
  align-items: center
  &--before
    border-radius: 0 16px 16px 0
  &--after
    border-radius: 16px 0 0 16px
  &--before svg
    transform: translateX(-2px)
  &--after svg
    transform: translateX(2px)
  &--before[disabled],
  &--after[disabled]
    background-color: var(--accent-dark)
    cursor: not-allowed
    opacity: 0.2

.pagination
  border-radius: 24px
  width: 50px
  height: 50px
  border-radius: 100px
  border: 0
  cursor: pointer
  font-family: 'PPNeueMachina'
  display: flex
  justify-content: center
  align-items: center
  &--active
    background-color: var(--accent-light)
  &--inactive
    background-color: var(--accent-dark)

.buttonsPages_buttonLeft
  transform: translateX(-30%)

.buttonsPages_buttonRight
  transform: translateX(30%)

//@media screen and (max-width: 900px)

@media screen and (max-width: 720px)
 .jobs-container
  min-height: 200vh
 .bundle_cardwork
  width: 90%
 .buttons_pages
  width: 80%
</style>
