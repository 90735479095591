<template>
    <div class="popup" v-if="jobDetailsSelected != Object">
        <div class="popUp_container">
            <div class="close_icon_container">
                <div class="closeIcon" @click="$emit('closePopUp')">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                        <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/>
                    </svg>
                </div>
            </div>
            <div class="popupDefinitions popupcontent--animation">
                <div class="popupHeader_level">
                    <div class="popupHeader_senority breadcrumb"> {{ this.jobDetailsSelected.seniority }} </div>
                    <div class="popupHeader_location breadcrumb">{{ this.jobDetailsSelected.location }}</div>
                </div>
                <div class="newbox breadcrumb breadcrumb--green">Nuevo</div>
            </div>

            <div class="popup_main">
                <div class="popup_arrangement popupcontent--animation">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" class="popup_location-icon">
                        <path
                            d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 400Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Z" />
                    </svg>
                    <span>{{ this.jobDetailsSelected.arrangement }}</span>
                </div>
                <h1 class="popup_main_title popupcontent--animation"> {{ this.jobDetailsSelected.name }}</h1>
                <div class="popup_main_description markdown-container popupcontent--animation">
                    <vue-markdown :source="this.jobDetailsSelected.description" />
                </div>
            </div>

            <div class="popup_companyInfo popupcontent--animation">
                <div>
                    <div class="popup_companyInfo_image popup_companyInfo_image--default">
                        {{this.jobDetailsSelected.company.name[0] }}
                    </div>
                </div>
                <div>
                    <p class="popup_companyInfo_name">
                        {{ this.jobDetailsSelected.company.name }}
                    </p>
                    <a :href="this.jobDetailsSelected.company.website" target="_blank" class="popup_companyInfo_name">
                        {{ this.jobDetailsSelected.company.website}}
                    </a>
                    <p class="popup_companyInfo_description">
                        {{ this.jobDetailsSelected.company.description }}
                    </p>
                </div>

            </div>

            <a :href="this.jobDetailsSelected.submission" target="_blank" class="popup_applybutton popupcontent--animation">Aplicar
                <svg class="popup_applybutton_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                    <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
                </svg>
            </a>
        </div>
        <div class="overlay" @click="$emit('closePopUp')"></div>

    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-render'

export default {
    name: 'PopupJob',
    components: {
        VueMarkdown
    },
    data() {
        return {
            jobDetailsSelected: Object,
        }
    },
    props: {
        idJob: {
            type: [Function, String]
        },
        currentPage: Number
    },

    watch: {
        idJob() {
            const requestOptions = {
				method: "GET",
				redirect: "follow",
				headers: {
					"Content-Type": "application/json",
                    "Accept-Language": "es",
				},
			}
            fetch(`${process.env.VUE_APP_JOBS_API_BASE_URL}/api/job/${this.idJob}/`, requestOptions)
                .then(res => res.json())
                .then(res => this.jobDetailsSelected = res)
                .then(() => {
                    this.$router.push({ query: { 'page': this.currentPage }, path: `/job/${this.idJob}` })
                })
                .then( () => {this.appearElementsAnimation()})
                .catch(err => console.log(err))
            
        }
    },

    methods: {
        appearElementsAnimation: function(){
            let invisibleElements = document.querySelectorAll('.popupcontent--animation');
            [...invisibleElements].map( (item, index) => {
                item.classList.add('popupcontent--animation');
                item.style.animationDelay = `${0.1 * index}s`
            })
        }
    }
}
</script>

<style lang="sass">

.popup
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    display: flex
    justify-content: center
    align-items: center
    z-index: 1001

.overlay
    width: 100%
    height: 100%
    position: fixed
    top: 0
    left: 0
    background-color: rgba(0,0,0,0.9)
    cursor: pointer
    backdrop-filter: blur(10px)

.popUp_container
    display: flex
    flex-direction: column
    width: 60%
    height: 90%
    background-color: #fff
    border-radius: 24px
    padding: 20px 60px
    box-sizing: border-box
    z-index: 99
    overflow-y: scroll
    -ms-overflow-style: none
    scrollbar-width: none
    &::-webkit-scrollbar
        display: none

.close_icon_container
    width: 100%
    margin-bottom: 20px
    display: flex
    justify-content: flex-end
    position: sticky
    top: 0px

.closeIcon
    width: 50px
    height: 50px
    border-radius: 30px
    background-color: #ee443c
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    & svg
        width: 30px
        height: 30px
    & svg path
        fill: #FFF
    &:hover
        background-color: #9b100a

.breadcrumb
    display: flex
    align-items: center
    padding: 10px 20px
    border-radius: 8px
    font-size: 12px
    color: #fff
    font-weight: bold
    background-color: var(--black)
    &--green
        font-family: 'PPNeueMachina'
        background-color: var(--accent-light)
        color: var(--black)
        text-transform: uppercase

.popupDefinitions
    display: flex
    justify-content: space-between
    padding-bottom: 20px
    & .breadcrumb--green
        border-radius: 90px

.popupHeader_level
    display: flex
    & .popupHeader_senority,
    & .popupHeader_location
        margin-right: 16px

.popup_main
.popup_main_title
    font-size: 32px
    font-family: 'PPNeueMachina'
    margin-bottom: 20px

.popup_location-icon
    width: 16px
    fill: #ee443c

.popup_arrangement
    margin-bottom: 32px
    & span
        margin-left: 8px
        font-size: 12px

.popup_main_description
    padding-bottom: 50px

.markdown-container
  font-family: Arial, sans-serif
  font-size: 16px
  line-height: 1.6
  color: #333

  h1
    font-size: 24px
    font-weight: bold
    margin-top: 30px
    margin-bottom: 20px

  h2
    font-size: 20px
    font-weight: bold
    margin-top: 25px
    margin-bottom: 15px

  h3
    font-size: 18px
    font-weight: bold
    margin-top: 20px
    margin-bottom: 10px

  p
    margin-top: 15px
    margin-bottom: 15px

  ul,
  ol
    margin-top: 15px
    margin-bottom: 15px
    padding-left: 20px

    li
      margin-bottom: 10px

  a
    color: #007bff
    text-decoration: none

    &:hover
      text-decoration: underline

  code
    font-family: Consolas, monospace
    background-color: #f8f9fa
    padding: 2px 4px
    border-radius: 4px

  pre
    font-family: Consolas, monospace
    background-color: #f8f9fa
    padding: 10px
    border-radius: 4px
    overflow-x: auto

  blockquote
    margin-top: 0
    margin-bottom: 15px
    padding: 10px 20px
    border-left: 5px solid #007bff
    background-color: #f8f9fa
    color: #333

    p
      margin-top: 0
      margin-bottom: 0

.popup_companyInfo
    display: flex

.popup_companyInfo_image
    width: 68px
    height: 68px
    margin-right: 24px
    border-radius: 50px

.popup_companyInfo_image--default
    background-color: var(--black)
    color: var(--accent-light)
    display: flex
    justify-content: center
    align-items: center
    font-size: 48px
    font-family: 'PPNeueMachina'

.popup_companyInfo_name
    font-size: 16px
    font-family: 'PPNeueMachina'
    text-decoration: none
    color: #000
    &:hover
        color: var(--accent-light)

.popup_applybutton
    padding: 20px 40px
    background-color: var(--accent-light)
    display: flex
    justify-content: center
    align-items: center
    width: 200px
    border-radius: 40px
    margin: auto
    margin-top: 40px
    font-size: 16px
    font-family: 'PPNeueMachina'
    color: var(--black)
    text-decoration: none
    box-sizing: border-box
    transition: all 0.3s ease-in
    &:hover
        color: var(--accent-light)
        background-color: var(--black)
        transition: all 0.3s ease-in
        .popup_applybutton_icon
            transition: all 0.3 ease-in
            fill: var(--accent-light)

.popup_applybutton_icon
    width: 30px
    height: 30px
    margin-left: 20px
    fill: var(--black)

@media screen and (max-width: 720px)
    .popUp_container
        width: 95%
        padding: 20px 40px

    .popup_main_title
        font-size: 28px
        overflow-wrap: break-word

.popupcontent--animation
    opacity: 0
    transform: translateY(50%)
    filter: blur(30px)
    animation: animationAppear .5s ease-out forwards

@keyframes animationAppear
    0%
        opacity: 0
        transform: translateY(50%)
        filter: blur(30px)
    100%
        opacity: 1
        transform: translateY(0%)
        filter: blur(0px)

</style>