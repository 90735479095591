<template>
    {{ this.$route }}
    <Header/>
    <ContainerCards/>
</template>

<script>

// import { useRoute } from 'vue-router';
// import PopupJob from '@/components/PopupJob.vue'
import Header from '@/components/Header.vue'
import ContainerCards from '@/components/ContainerCards.vue'

export default{
    name: 'jobInformation',
    components: {
        Header,
        ContainerCards
        // PopupJob,
    },
    props: ['jobDetails', 'idJob'],
    created() {
        
    }
}
</script>
