<template>

  <div class="cardwork" :style="{animationDelay: this.delay * 200 + 'ms'}">
	<p class="cardwork_creationdate">
		<span v-if="jobData.days_from_creation < 5" class="cardwork_title_badge">NUEVO</span>Publicado {{ jobData.days_from_creation === 0 ? 'hoy': 'hace ' + jobData.days_from_creation + ' días' }}
	</p>
    <div class="cardwork_upelements" :href="jobData.id">
      <p class="cardwork_location">{{ jobData.location }}</p>
      <p class="cardwork_term">{{ jobData.term }}</p>
    </div>
    <p class="cardwork_title">
		{{ jobData.name }}
    </p>
    <p class="cardwork_subtitle">{{ jobData.company.name }}</p>
    
    <div class="cardwork_downelements">
      <p class="cardwork_seniority">Exp: <span>{{ jobData.seniority }}</span></p>
      <div v-if="jobData.salary" class="cardwork_downelements_group">
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 30 30"
          class="cardwork_downelements--icons">
          <path fill="#a7ff38" d="M9.3 19a6 6 0 0 0 1.6 2.8c.7.7 1.7 1.2 2.8 1.5v.7c0 .3.1.6.4.9.3.3.6.4.9.4.3 0 .6-.1.9-.4.3-.3.4-.6.4-.9v-.6c1.2-.2 2.3-.7 3.2-1.4.9-.7 1.3-1.8 1.3-3.3 0-1-.3-2-.9-2.8-.6-.9-1.8-1.6-3.5-2.2-1.5-.5-2.5-.9-3-1.3-.6-.4-.8-.9-.8-1.5s.2-1.1.7-1.5a3 3 0 0 1 2-.6c.8 0 1.4.2 1.8.6s.8.9 1 1.4l2.3-1c-.3-.9-.8-1.6-1.5-2.2-.7-.6-1.5-1-2.4-1.1V6c0-.3-.1-.6-.4-.9-.3-.3-.6-.4-.9-.4-.3 0-.6.1-.9.4-.3.3-.4.6-.4.9v.6c-1.2.3-2.2.8-2.9 1.6s-1 1.7-1 2.7c0 1.1.3 2.1 1 2.8a8 8 0 0 0 3.2 1.8c1.5.6 2.6 1.1 3.2 1.5s.9 1 .9 1.7a2 2 0 0 1-.9 1.8c-.6.4-1.3.6-2.1.6s-1.5-.3-2.1-.8a4.8 4.8 0 0 1-1.4-2.3l-2.5 1zM15 29.7c-2 0-3.9-.4-5.7-1.2a13.8 13.8 0 0 1-7.8-7.8 13.9 13.9 0 0 1 0-11.4C2.3 7.5 3.3 6 4.6 4.6s2.9-2.4 4.7-3.1S13 .3 15 .3s3.9.4 5.7 1.2 3.3 1.8 4.7 3.1c1.3 1.3 2.4 2.9 3.1 4.7a13.9 13.9 0 0 1 0 11.4 13.8 13.8 0 0 1-7.8 7.8c-1.8.8-3.7 1.2-5.7 1.2z" />
        </svg>
        <p class="cardwork_salary">{{ jobData.salary }} {{ jobData.salary_currency }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardWork',
  props: ['jobData', 'delay']
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.cardwork
	padding: 2rem
	background-color: var(--accent-dark)
	display: flex
	flex-direction: column
	box-sizing: border-box
	border-radius: 24px
	color: #fff
	text-decoration: none
	transition: all 0.1s ease-in-out
	cursor: pointer
	position: relative
	animation: card-appear .2s ease-in
	animation-fill-mode: both
	max-height: 400px
	&:hover
		transform: translateY(-15px)
		transition: all 0.2s ease-in-out

.cardwork_title_badge
	display: inline-block
	background-color: var(--accent-light)
	color: var(--black)
	font-size: 8px	
	border-radius: 16px
	font-family: 'PPNeueMachina'
	padding: 4px 8px
	vertical-align: top
	margin-right: 8px

.cardwork_creationdate
	font-size: 12px
	margin-bottom: 20px
	color: #FFF
	vertical-align: center

.cardwork_upelements,
.cardwork_downelements
	width: 100%
	display: flex
	flex-direction: row
	justify-content: space-between
	font-size: 12px
	& *
		margin: 0

.cardwork_upelements
	border-bottom: 1px solid #fff
	padding-bottom: 15px
	margin-bottom: 15px

.cardwork_location,
.cardwork_term
	padding: 5px 10px
	border-radius: 5px
	background-color: var(--black)
	color: #fff

.cardwork_title
	font-size: 22px
	display: inline-block
	margin-bottom: 15px
	line-height: 25px
	position: relative
	font-weight: bold

.cardwork_subtitle
	font-size: 14px
	color: var(--accent-light)
	margin-bottom: 10px

.cardwork_title,
.cardwork_description
	display: -webkit-box
	-webkit-line-clamp: 3
	-webkit-box-orient: vertical
	overflow: hidden
	text-overflow: ellipsis

.cardwork_downelements
	margin-top: 10px
	color: var(--accent-light)

.cardwork_seniority
	color: #FFF
	& span
		color: var(--accent-light)
		
.cardwork_downelements_group
	display: flex

.cardwork_downelements--icons
	width: 15px
	padding-right: 5px
	fill: var(--accent-light)

@media screen and (max-width: 600px)
	.cardwork
		width: 90%
		margin: auto

@keyframes card-appear
	from
		opacity: 0
		transform: translateY(30px)
	to
		opacity: 1
		transform: translateY(0px)
</style>
