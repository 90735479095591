<template>
  <div class="loader"></div>
</template>

<script>
export default {
  name: 'SpinnerLoader',
};
</script>

<style scoped lang="sass">
.loader
  margin: auto
  width: 100px
  padding: 10px
  aspect-ratio: 1
  border-radius: 50%
  background: #a7ff38
  text-align: center
  --_m:conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box
  -webkit-mask: var(--_m)
  mask: var(--_m)
  -webkit-mask-composite: source-out
  mask-composite: subtract
  animation: l3 1s infinite linear

@keyframes l3
  to
    transform: rotate(1turn)
</style>
